import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, CircularProgress, Paper } from "@mui/material";
import { styled } from "@mui/system";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(4),
  textAlign: "center",
  backgroundColor: "#f5f5f5",
}));

function ShortlistedDrivers() {
  const { bookingId } = useParams();
  const [shortlistedDrivers, setShortlistedDrivers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDriversDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://api.holocabs.in/booked/shortlisted-drivers/${bookingId}`,
          {
            headers: { Authorization: token },
          }
        );

        setShortlistedDrivers(response.data.drivers || []);
      } catch (err) {
        console.error("Error fetching drivers:", err);
      } finally {
        setLoading(false);
      }
    };

    getDriversDetails();
  }, [bookingId]);

  const columns = [
    { field: "order", headerName: "Order", width: 100 },
    { field: "driverName", headerName: "Driver Name", flex: 1 },
    { field: "driverMobile", headerName: "Mobile Number", flex: 1 },
  ];

  const rows = shortlistedDrivers.map((driver, index) => ({
    id: index + 1,
    order: index + 1,
    driverName: driver.driverName,
    driverMobile: driver.driverMobile,
  }));

  return (
    <StyledPaper>
      <Typography variant="h4" gutterBottom>
        Shortlisted Drivers for Booking ID: {bookingId.slice(-10)}
      </Typography>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : shortlistedDrivers.length > 0 ? (
        <Box sx={{ height: 400, width: "100%", mt: 2 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            sx={{
              "& .MuiDataGrid-root": {
                backgroundColor: "#fff",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#f5f5f5",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-cell": {
                fontSize: "1rem",
              },
            }}
          />
        </Box>
      ) : (
        <Typography variant="body1" color="textSecondary" sx={{ mt: 4 }}>
          No shortlisted drivers found for this booking.
        </Typography>
      )}
    </StyledPaper>
  );
}

export default ShortlistedDrivers;
