import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import Table from "../../components/table/Table";
// import Leaderboard from "../../components/Leaderboard/Leaderboard";

const Home = () => {
  return (
    <>
      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <Navbar />
          <div className="widgets">
            <Widget type="user" />
            <Widget type="order" />
            <Widget type="earning" />
            <Widget type="balance" />

            <Widget type="onlineDrivers" />
            <Widget type="onlineAutoDrivers" />
            <Widget type="onlineCabDrivers" />
            <Widget type="offlineDrivers" />
            <Widget type="ridingDrivers" />
            <Widget type="regularVehicles" />
            <Widget type="primeSedanVehicles" />
            <Widget type="suvPremiumVehicles" />
            <Widget type="HoloAutoVehicles" />
            <Widget type="DriversDownload" />
            <Widget type="ActiveDriversDownload" />
            <Widget type="CabsDriversDownload" />
          </div>
          {/* <div className="listContainer">
            <div className="listTitle">Leaderboard</div>
            <Leaderboard />
          </div> */}
          <div className="charts">
            <Featured />
            <Chart title="last 6 months (revenue)" aspect={2 / 1} />
          </div>
          <div className="listContainer">
            <div className="listTitle">Latest Transactions</div>
            <Table />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
