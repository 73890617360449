import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";

function OtpLogs() {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const eventSource = new EventSource(
      `https://api.holocabs.in/driverConfirm/getotplogs`
    );

    eventSource.onmessage = (event) => {
      const newLog = JSON.parse(event.data);
      setLogs((prevLogs) => [newLog, ...prevLogs]);

    };

    // Error handling
    eventSource.onerror = (error) => {
      console.error("EventSource error:", error);
      eventSource.close();
    };

    // Cleanup on component unmount
    return () => {
      eventSource.close();
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        bgcolor: "#f5f5f5",
      }}
    >
      <Box
        sx={{
          p: 2,
          bgcolor: "#3f51b5",
          color: "#fff",
          textAlign: "center",
        }}
      >
        <Typography variant="h4">OTP Logs</Typography>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            maxWidth: "600px",
            maxHeight: "90%",
            p: 2,
            bgcolor: "#fff",
            borderRadius: 2,
            overflowY: "auto",
          }}
        >
          <List>
            {logs.length === 0 ? (
              <Typography
                variant="body1"
                sx={{ textAlign: "center", color: "gray" }}
              >
                No OTPs generated yet.
              </Typography>
            ) : (
              logs.map((log, index) => (
                <ListItem key={index} divider>
                  <ListItemText
                    primary={`OTP ${log.OTP} generated for ${log.mobile}`}
                    secondary={`Received at ${new Date().toLocaleTimeString()}`}
                  />
                </ListItem>
              ))
            )}
          </List>
        </Paper>
      </Box>
    </Box>
  );
}


export default OtpLogs;
