import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Cookies from "js-cookie";

import Home from "./pages/home/Home";
import List from "./pages/list/list";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import Map from "./components/Maps/Map";
import RideHistory from "./components/RideHistory";
import Login from "./components/Login";
import NewUser from "./components/NewUser";
import EditDrivers from "./components/EditDrivers";
import Singlemaps from "./components/Singlemaps";
import ActivationList from "./pages/activationm/ActivationList";
import RenewalList from "./pages/showrenewal/RenewalList";
import DriverSOSd from "./pages/driverSOSdata/DriverSOSd";
import PassengerSOSd from "./pages/passengerSOSdata/PassengerSOSd";
import Rewardsm from "./pages/Rewardsp/rewards";
import Settlement from "./pages/settlementdata/settlementd";
import MapsPage from "./pages/map/MapsPage";
import CancelledRides from "./pages/Cancelled/CancelledRides";
import Tshirt from "./components/tshirtdetails/Tshirt";
import Notification from "./components/notification/Notification";
import SettlementPromo from "./pages/promosettlement/SettlementPromo";
import axios from "axios";
import ShortlistedDrivers from "./components/ShortlistedDrivers";
import OtpLogs from "./pages/otplogs/OtpLogs";

function App() {
  // Check if username and password cookies exist
  const [isAuthenticated, setisAuthenticated] = useState(false);

  useEffect(() => {
    const fetchToken = async () => {
      const isToken = localStorage.getItem("token");
      if (isToken) {
        const res = await axios.get(
          "https://api.holocabs.in/admin/adminAuthCheck",
          {
            headers: {
              Authorization: isToken,
            },
          }
        );
        if (res.data.success) {
          setisAuthenticated(true);
        } else {
          setisAuthenticated(false);
        }
      }
    };

    fetchToken();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            {isAuthenticated ? (
              <>
                <Route index element={<Home />} />
                <Route path="map/:long/:lat" element={<Map />} />
                <Route path="ridehistory" element={<RideHistory />} />
                <Route path="cancelledrides" element={<CancelledRides />} />

                <Route path="users">
                  <Route index element={<List />} />
                  <Route path=":userId" element={<Single />} />
                  <Route path="new" element={<NewUser />} />
                </Route>

                <Route path="drivers">
                  <Route index element={<List />} />
                  <Route path=":productId" element={<Single />} />
                </Route>
                <Route path="activation">
                  <Route index element={<ActivationList />} />
                </Route>
                <Route path="documents">
                  <Route index element={<RenewalList />} />
                </Route>

                <Route
                  path="shortlistedset/:bookingId"
                  element={<ShortlistedDrivers />}
                />
                <Route path="editdrivers/:id" element={<EditDrivers />} />
                <Route path="/maps" element={<MapsPage />} />
                <Route path="/driversos" element={<DriverSOSd />} />
                <Route path="/passengersos" element={<PassengerSOSd />} />
                <Route path="/tshirtoffer" element={<Tshirt />} />
                <Route path="/settlement" element={<Settlement />} />
                <Route path="/rewards" element={<Rewardsm />} />
                <Route path="/sendnotification" element={<Notification />} />
                <Route path="/promosettlement" element={<SettlementPromo />} />
                <Route path="/getoptlogs" element={<OtpLogs />} />
              </>
            ) : (
              <Route index path="/" element={<Login />} />
            )}
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/Singlemaps/:Livelocationkey" element={<Singlemaps />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
