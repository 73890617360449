import React, { useEffect, useState } from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import GetAppIcon from "@mui/icons-material/GetApp";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { driverColumns } from "../../datatablesource";

const ActivationModule = () => {
  const [data, setData] = useState([]);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [activateDriverId, setActivateDriverId] = useState(null);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [deactivateDriverId, setDeactivateDriverId] = useState(null);

  // useEffect(() => {
  //   axios
  //     .get('https://api.holocabs.in/getalldriver')
  //     .then((response) => {
  //       const updatedData = response.data.map((row) => ({ ...row, id: row._id }));
  //       setData(updatedData);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);
  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .get("https://api.holocabs.in/driver/getalldriver", {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const updatedData = response.data.map((row) => ({
          ...row,
          id: row._id,
        }));
        setData(updatedData.reverse());
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleActivate = (id) => {
    if (!data.find((driver) => driver.id === id).isActivated) {
      // Only show the confirmation modal if the driver is not already activated
      setActivateDriverId(id);
      setShowActivateModal(true);
    }
  };

  const handleDeactivate = (id) => {
    setDeactivateDriverId(id);
    setShowDeactivateModal(true);
  };

  const confirmActivate = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `https://api.holocabs.in/driver/drivers/do-activation/${activateDriverId}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.data.success) {
        // Handle success, maybe update the UI or show a success message
        toast.success("Driver activated successfully");
        setTimeout(() => {
          window.location.reload();
        }, 7000); // 5000 milliseconds = 5 seconds
      } else {
        // Handle failure, show an error message or take appropriate action

        toast.error("Failed to activate driver");
      }
    } catch (error) {
      console.error("Error activating driver:", error);
    } finally {
      setActivateDriverId(null);
      setShowActivateModal(false);
    }
  };

  const confirmDeactivate = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `https://api.holocabs.in/driver/drivers/do-deactivation/${deactivateDriverId}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.data.success) {
        // Handle success, maybe update the UI or show a success message

        toast.success("Driver deactivated successfully");
        setTimeout(() => {
          window.location.reload();
        }, 7000); // 5000 milliseconds = 5 seconds
      } else {
        // Handle failure, show an error message or take appropriate action

        toast.error("Failed to deactivate driver");
      }
    } catch (error) {
      console.error("Error deactivating driver:", error);
    } finally {
      setDeactivateDriverId(null);
      setShowDeactivateModal(false);
    }
  };

  const cancelActivate = () => {
    setActivateDriverId(null);
    setShowActivateModal(false);
  };

  const cancelDeactivate = () => {
    setDeactivateDriverId(null);
    setShowDeactivateModal(false);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => (
        <div
          className="cellAction"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <div
            className="activateButton"
            onClick={() => handleActivate(params.row.id)}
            style={{
              backgroundColor: params.row.isActivated ? "#ccc" : "#4CAF50",
              color: "white",
              padding: "4px",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
              marginRight: 10,
            }}
            disabled={params.row.isActivated} // Disable the button if isActivated is true
          >
            Activate
          </div>
          <div
            className="deactivateButton"
            onClick={() => handleDeactivate(params.row.id)}
            style={{
              backgroundColor: "#F44336",
              color: "white",
              padding: "4px",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            Deactivate
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="activation-module">
      <ToastContainer />
      <DataGrid
        className="datagrid"
        rows={data}
        columns={actionColumn.concat(driverColumns)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />

      {/* Activation Confirmation Modal */}
      <Modal
        isOpen={showActivateModal}
        onRequestClose={cancelActivate}
        style={{
          content: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "300px",
            height: "150px",
            margin: "auto",
          },
        }}
      >
        <h3>Confirm Activation</h3>
        <p>Are you sure you want to activate this driver?</p>
        <div>
          <button
            onClick={confirmActivate}
            style={{
              backgroundColor: "#4CAF50",
              border: "none",
              color: "white",
              padding: "10px 20px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              margin: "4px 2px",
              cursor: "pointer",
              borderRadius: "4px",
            }}
          >
            Activate
          </button>
          <button
            onClick={cancelActivate}
            style={{
              backgroundColor: "#2196F3",
              border: "none",
              color: "white",
              padding: "10px 20px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              margin: "4px 2px",
              cursor: "pointer",
              borderRadius: "4px",
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>

      {/* Deactivation Confirmation Modal */}
      <Modal
        isOpen={showDeactivateModal}
        onRequestClose={cancelDeactivate}
        style={{
          content: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "300px",
            height: "150px",
            margin: "auto",
          },
        }}
      >
        <h3>Confirm Deactivation</h3>
        <p>Are you sure you want to deactivate this driver?</p>
        <div>
          <button
            onClick={confirmDeactivate}
            style={{
              backgroundColor: "#F44336",
              border: "none",
              color: "white",
              padding: "10px 20px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              margin: "4px 2px",
              cursor: "pointer",
              borderRadius: "4px",
            }}
          >
            Deactivate
          </button>
          <button
            onClick={cancelDeactivate}
            style={{
              backgroundColor: "#2196F3",
              border: "none",
              color: "white",
              padding: "10px 20px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              margin: "4px 2px",
              cursor: "pointer",
              borderRadius: "4px",
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ActivationModule;
