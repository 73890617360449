import React from "react";
import "./OtpLogs.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import OtpLogs2 from "../../components/otplogs/otplogs";
const OtpLogs = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <OtpLogs2 />
      </div>
    </div>
  );
};

export default OtpLogs;
